.blog-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/blog-page-header.jpg);
}

.malaysia-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/malaysia.webp);

}

.singapore-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/singapore.jpg);

}

.australia-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/australia.jpg);

}


.canada-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/canada.jpg);

}

.europe-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/europe.png);

}

.uk-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/uk.jpg);

}

.newzealand-page {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../Images/newzeland.jpg);

}






.first-letter {
    color: white;
    background-color: gray;
    outline: auto;
    font-size: 35px;
    line-height: 70px;
  }