.international-addresses {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.address-card {
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.address-card img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.address-card h2 {
  color: #333;
  margin-bottom: 10px;
}

.address-card p {
  color: #555;
  line-height: 1.5;
  margin: 5px 0;
}
