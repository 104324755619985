.card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .card-text {
    font-size: 1rem;
  }
  